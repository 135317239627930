<template>
  <div class="p-2">
    <!-- Table -->
    <filter-slot
      :filter="filter"
      :totalRows="totalRows"
      :paginate="paginate"
      :startPage="startPage"
      :toPage="toPage"
      :filter-principal="filterPrincipal"
      @reload="$refs['refClientsList'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          small
          table-class="text-nowrap"
          :items="myProvider"
          :fields="visibleFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          ref="refClientsList"
          :busy="isBusy"
          sticky-header="60vh"
          show-empty
          responsive="sm"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <router-link
              :class="[textLink]"
              :to="`/${nameModule}/clients/account/${data.item.account_id}`"
              target="_blank"
            >
              {{ data.item.client_name }}
            </router-link>
          </template>

          <template #cell(task)="data">
            <b-row cols="2" class="text-primary">
              <b-col class="text-left">
                <span class="mr-3">{{ data.item.task }}</span>
              </b-col>
              <b-col class="text-right">
                <tabler-icon
                  icon="InfoCircleIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="openModalTaskCallRounds(data.item)"
                />
              </b-col>
            </b-row>
          </template>

          <template #cell(date_t)="data">
            <div>
              {{ data.item.date_t | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(date_cool)="data">
            <div>
              {{ data.item.date_cool | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(status)="data">
            <div>
              <b-badge v-if="data.item.status == 1" variant="warning"
                >PENDING</b-badge
              >
              <b-badge v-if="data.item.status == 2" variant="primary"
                >UNDERVIEW</b-badge
              >
              <b-badge v-if="data.item.status == 3" variant="danger"
                >UNCOMPLETED</b-badge
              >
              <b-badge v-if="data.item.status == 4" variant="success"
                >COMPLETED</b-badge
              >
            </div>
          </template>

          <template #cell(call_data)="data">
            <div class="text-center">
              <b-badge
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                :class="`mr-1 ' ${
                  data.item.counter <= 0 ? null : 'cursor-pointer'
                }`"
                variant="danger"
                @click="
                  data.item.counter <= 0
                    ? null
                    : openModalCallCounter(data.item, 0)
                "
              >
                <feather-icon icon="MinusIcon" />
              </b-badge>
              <b-badge
                :id="`counter-target-${data.index}`"
                class="cursor-pointer"
                variant="primary"
                style="width: 40px"
              >
                {{ data.item.counter }}
              </b-badge>
              <b-tooltip
                v-if="data.item.counter >= 1"
                :target="`counter-target-${data.index}`"
                triggers="hover"
                placement="left"
                ><div>
                  <span
                    v-for="(item, i) in JSON.parse(data.item.call_data)"
                    :key="i"
                  >
                    {{ item.dates_calls | myGlobalWithHour }} <br />
                  </span></div
              ></b-tooltip>
              <b-badge
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                class="ml-1 cursor-pointer"
                variant="success"
                @click="openModalCallCounter(data.item, 1)"
              >
                <feather-icon icon="PlusIcon" />
              </b-badge>
            </div>
          </template>

          <template #cell(action)="data">
            <b-dropdown
              boundary="viewport"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  style="position: absolute; top: 0px"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="currentUser.role_id != 4"
                @click="openModalCompletedRounds(data.item, 4)"
                variant="success"
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Completed</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="currentUser.role_id == 4 && data.item.status == 1"
                @click="openModalAddtracking(data.item, 2)"
                variant="primary"
              >
                <feather-icon icon="PhoneForwardedIcon" />
                <span class="align-middle ml-50">Send to Revision</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="
                  openModalSendSMS(data.item.account_id, data.item.client_name)
                "
                variant="primary"
              >
                <feather-icon icon="MessageCircleIcon" />
                <span class="align-middle ml-50">Send SMS</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-task-call-rounds
      v-if="modalTaskCallRounds"
      :callRoundUser="callRoundUser"
      @closeModal="closeModal"
    />

    <modal-call-counter
      v-if="modalCallCounter"
      :callRoundUser="callRoundUser"
      :amount="amount"
      @closeModal="closeModal"
      @reload="$refs['refClientsList'].refresh()"
    />

    <modal-add-tracking
      v-if="modalAddTracking"
      :callRoundUser="callRoundUser"
      :status="trackingStatus"
      @closeModal="closeModal"
      @reload="$refs['refClientsList'].refresh()"
    />

    <modal-send-sms
      v-if="modalSendSMS"
      :nameLeads="clientsSMS[0]"
      @closeModal="closeModal"
    />

    <modal-completed-rounds
      v-if="modalCompletedRounds"
      :callRoundUser="callRoundUser"
      :status="completedRoundsStatus"
      @closeModal="closeModal"
      @reload="$refs['refClientsList'].refresh()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CallRoundsService from "../../service/call-rounds.service";
import fields from "../data/fields.list.data";
import filters from "../data/filter.list.data";
// modals
import ModalTaskCallRounds from "@/views/commons/call-rounds/components/modals/TaskCallRounds.vue";
import ModalCallCounter from "@/views/commons/call-rounds/components/modals/CallCounter.vue";
import ModalAddTracking from "@/views/commons/call-rounds/components/modals/AddTracking.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalCompletedRounds from "@/views/commons/call-rounds/components/modals/CompletedRounds.vue";

// Import Component
// import PendingDone from "./subcomponents/PendingDone.vue";

export default {
  components: {
    // PendingDone
    ModalTaskCallRounds,
    ModalCallCounter,
    ModalAddTracking,
    ModalSendSms,
    ModalCompletedRounds,
  },
  data: function () {
    return {
      clientsSMS: [],
      callRoundUser: {},
      items: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      nextPage: 0,
      endPage: 0,
      searchInput: "",
      startPage: null,
      toPage: null,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      filter: filters,
      totalRows: 0,
      arrayColumns: fields.filter(
        (col) =>
          col.key != "operator_name" &&
          col.key != "updated_at" &&
          col.key != "comments"
      ),
      modalTaskCallRounds: false,
      modalCallCounter: false,
      modalAddTracking: false,
      modalSendSMS: false,
      modalCompletedRounds: false,
      completedRoundsStatus: null,
    };
  },
  async created() {
    if (this.filter[3].options[0].text != "All") {
      this.filter[3].options.unshift({
        text: "All",
        value: this.currentUser.role_id == 3 ? 0 : 5,
      });
    }
    this.getAdvisor();
  },
  mounted() {
    this.filter[3].model = this.currentUser.role_id == 3 ? 0 : 5;
  },
  methods: {
    async getAdvisor() {
      try {
        const params = {
          roles: "[3,2]",
          type: "0",
        };
        const data = await CallRoundsService.getAdvisors(params, this.moduleId);
        this.filter[4].options = data.data;
      } catch (e) {
        console.log(e);
      }
    },
    openModalCompletedRounds(item, status) {
      this.callRoundUser = item;
      this.completedRoundsStatus = status;
      this.modalCompletedRounds = true;
    },
    openModalSendSMS(id, name) {
      this.clientsSMS = [{ id: id, name: name }];
      this.modalSendSMS = true;
    },
    openModalAddtracking(item, status) {
      this.trackingStatus = status;
      this.callRoundUser = item;
      this.modalAddTracking = true;
    },
    openModalCallCounter(item, amount) {
      this.callRoundUser = item;
      this.amount = amount;
      this.modalCallCounter = true;
    },
    openModalTaskCallRounds(item) {
      this.callRoundUser = item;
      this.modalTaskCallRounds = true;
    },
    myProvider: async function (ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          status: this.filter[3].model == null ? 5 : this.filter[3].model,
          advisor: this.filter[4].model /*this.advisor*/,
          program: this.$route.meta.programId,
          operator: /*this.operator*/ null,
          tr: 1,
          user_id:
            this.currentUser.role_id != 3
              ? 0
              : this.currentUser
                  .user_id /*this.global.layout.role_id == 3 ? this.global.layout.id :*/,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          order: ctx.sortDesc ? 2 : 100, //? 2 : 100, //== 1 ? 1 : 2,
          task: null /*this.task*/,
          id_status_account: this.statusAccount,
          id_stand_by: 2,
          page: ctx.currentPage,
          perPage: this.paginate.perPage,
        };
        const data = await CallRoundsService.searchCallRounds(params);
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
      } catch (e) {
        console.log(e);
      }
      return this.items || [];
    },
    closeModal() {
      this.modalAddTracking = false;
      this.modalTaskCallRounds = false;
      this.modalViewTracking = false;
      this.modalCompletedRounds = false;
      this.modalSendSMS = false;
      this.modalSendToStandBy = false;
      this.modalCallCounter = false;
      this.modalCompletedRounds = false;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    nameModule() {
      const name = this.moduleId == 7 ? "boostcredit" : null;
      return name;
    },
    visibleFields: function () {
      if (this.isCeo || this.isSupervisor || this.isCoordinator) {
        return this.arrayColumns.filter((column) => column.visible);
      } else {
        let newArrayColumn = [...this.arrayColumns];
        // newArrayColumn.splice(3, 1);
        return newArrayColumn.filter((column) => column.visible);
      }
    },
  },
};
</script>
